import * as Sentry from '@sentry/browser';

const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    // Log to Sentry here
    Sentry.withScope((scope) => {
      scope.setExtra('actionData', {
        action,
        state: store.getState(),
      });
      Sentry.captureException(error);
    });
    throw error;
  }
};

export default crashReporter;
