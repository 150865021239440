import React, { useState, useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import styles from './Layout.module.css';
import Breadcrumbs from './Breadcrumbs';

export const LayoutDisplay = (props) => {
  const { children, currentLocation, apiCallsInProgress } = props;

  const minimumWindowHeight = window.innerHeight * 0.6;
  const [contentContainer] = document.getElementsByClassName('content-container');

  const [contentHeight, setContentHeight] = useState(minimumWindowHeight);
  const [sizerStyle, setSizerStyle] = useState({
    height: `${minimumWindowHeight}px`,
  });

  // return a either the minimumWindowHeight or the contentHeight if it's larger
  const calculateNewContentHeight = () => {
    let height = minimumWindowHeight;
    if (contentContainer && contentContainer.clientHeight > minimumWindowHeight) {
      height = contentContainer.clientHeight;
    }
    return height;
  };

  const doHeightStuff = () => {
    const newHeight = calculateNewContentHeight();
    if (newHeight !== contentHeight) {
      setContentHeight(newHeight);
    }
  };

  // when window is resized, check contentHeight (resizing)
  window.addEventListener('resize', () => {
    doHeightStuff();
  });

  // when route changes occur, check contentHeight (navigation)
  useEffect(() => {
    // also scroll the window to the top
    window.scrollTo(0, 0);
    // run it after 1/4 of a second to ensure that the contentContainer used
    // for this is not the one from the previous screen
    // feels a little hacky /shrug
    setTimeout(() => {
      doHeightStuff();
    }, 250);
  }, [currentLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  // when all api calls are done, recalc in case of alerts displayed on page affecting the height
  useEffect(() => {
    if (apiCallsInProgress === 0) {
      setTimeout(() => {
        doHeightStuff();
      }, 250);
    }
  }, [apiCallsInProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  // before DOM is done rendering, check contentHeight (refreshes)
  useLayoutEffect(() => {
    doHeightStuff();
  });

  // set sizerStyle on contentHeight changes
  useEffect(() => {
    // -30 due to overlap of some body components
    setSizerStyle({ height: `${contentHeight}px` });
  }, [contentHeight]);

  return (
    <Container fluid>
      <Header />
      <div className={styles.colorbar} />
      <div id='contentSizer' style={sizerStyle} />
      <div id='mainContent' className={styles.mainContent}>
        <div className='col-xs-12 col-sm-10 col-md-9 col-lg-9 col-xl-9 mx-auto'>
          <div className='container-shadow'>
            {location.pathname != '/' && (
              <div className='breadcrumb-container d-flex'>
                <Breadcrumbs />
              </div>
            )}
            <main role='main'>
              <Container className='content-container'>{children}</Container>
            </main>
          </div>
        </div>
      </div>
      <div className={styles.colorbarBottom}></div>
      <Footer />
    </Container>
  );
};

LayoutDisplay.propTypes = {
  children: PropTypes.node.isRequired,
  currentLocation: PropTypes.string.isRequired,
  apiCallsInProgress: PropTypes.number,
};

LayoutDisplay.defaultProps = {
  apiCallsInProgress: 0,
};

export const mapStateToProps = (state) => {
  return {
    currentLocation: state.router.location.pathname,
    apiCallsInProgress: state.apiCallsInProgress,
  };
};

export default connect(mapStateToProps, {})(LayoutDisplay);
