import React from 'react';
import PropTypes from 'prop-types';
import { Container, Card, CardColumns } from 'react-bootstrap';
import PasswordValidator from './PasswordValidator';
import styles from './PasswordValidation.module.css';

const PasswordValidation = (props) => {
  const { validationItems, title } = props;
  return (
    <Container className={styles.validatorContainer}>
      <p className={styles.title}>{title}</p>
      <CardColumns>
        {validationItems.map((vi, idx) => {
          const cardKey = `col-${idx}`;
          const pvKey = `validator-${idx}`;
          return (
            <Card key={cardKey} className='bg-transparent border-0'>
              <PasswordValidator key={pvKey} {...vi} />
            </Card>
          );
        })}
      </CardColumns>
    </Container>
  );
};

PasswordValidation.propTypes = {
  title: PropTypes.string.isRequired,
  validationItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PasswordValidation;
