import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import ErrorPage from './ErrorPage';

// there is no hook yet for componentDidCatch so have to use a class component instead of a function component
export class ErrorBoundaryDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  handleReportClick = (e) => {
    e.preventDefault();
    const { eventId } = this.state;
    Sentry.showReportDialog({ eventId });
  };

  refresh = () => {
    window.location.reload();
  };

  componentDidCatch(error, errorInfo) {
    const { environmentName } = this.props;
    this.setState({ error });
    if (environmentName !== 'Development') {
      Sentry.withScope((scope) => {
        scope.setExtra('errorInfo', errorInfo);
        const eventID = Sentry.captureException(error);
        this.setState({ eventId: eventID });
      });
    }
  }

  render() {
    const { error } = this.state;
    const { children, useRefresh } = this.props;

    if (error) {
      return <ErrorPage onReportClick={this.handleReportClick} useRefresh={useRefresh} refresh={this.refresh} />;
    }

    // When there's not an error render the children
    return children;
  }
}

ErrorBoundaryDisplay.propTypes = {
  environmentName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  useRefresh: PropTypes.bool,
};

ErrorBoundaryDisplay.defaultProps = {
  useRefresh: false,
};

export const mapStateToProps = (state) => {
  return {
    environmentName: state.defaults.environmentName,
  };
};

export default connect(mapStateToProps, {})(ErrorBoundaryDisplay);
