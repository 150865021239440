import * as types from '../actions/appActions';

const initialState = {
  environmentName: '',
  selectionsUrl: '',
  baseUrl: document.getElementsByTagName('base')[0].getAttribute('href'),
  w2Note: '',
  downTimeNote: '',
};

const appReducer = (state = initialState, action) => {
  if (action.type === types.GET_APP_DEFAULTS_SUCCESS) {
    return {
      ...state,
      environmentName: action.appDefaults.environmentName,
      selectionsUrl: action.appDefaults.selectionsUrl,
      w2Note: action.appDefaults.w2Note,
      downTimeNote: action.appDefaults.downTimeNote,
    };
  }

  return state;
};

export default appReducer;
