import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loader.module.css';

const Loader = (props) => {
  const { variant } = props;

  if (variant === 'dots') {
    return (
      <div className={styles.loaderDots}>
        <div className={`${styles.duo} ${styles.duo1}`}>
          <div className={`${styles.dot} ${styles.dotA}`} />
          <div className={`${styles.dot} ${styles.dotB}`} />
        </div>
        <div className={`${styles.duo} ${styles.duo2}`}>
          <div className={`${styles.dot} ${styles.dotA}`} />
          <div className={`${styles.dot} ${styles.dotB}`} />
        </div>
      </div>
    );
  }

  if (variant === 'box') {
    return (
      <div className={styles.cubeGrid}>
        <div className={`${styles.cube} ${styles.cube1}`} />
        <div className={`${styles.cube} ${styles.cube2}`} />
        <div className={`${styles.cube} ${styles.cube3}`} />
        <div className={`${styles.cube} ${styles.cube4}`} />
        <div className={`${styles.cube} ${styles.cube5}`} />
        <div className={`${styles.cube} ${styles.cube6}`} />
        <div className={`${styles.cube} ${styles.cube7}`} />
        <div className={`${styles.cube} ${styles.cube8}`} />
        <div className={`${styles.cube} ${styles.cube9}`} />
      </div>
    );
  }

  return <div className={styles.loader} />;
};

Loader.propTypes = {
  variant: PropTypes.string,
};

Loader.defaultProps = {
  variant: '',
};

export default Loader;
