import {
  SUBMIT_REGISTRATION_FORM_SUCCESS,
  CONFIRM_EMAIL_SUCCESS,
  CANCEL_REGISTRATION,
  CLEAR_STATE,
} from '../actions/registrationActions';

const initialState = {
  alumni: {},
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_REGISTRATION_FORM_SUCCESS:
      return {
        ...state,
        alumni: action.verificationStatus.alumni,
        apiErrors: action.verificationStatus.errors || [],
      };
    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        apiErrors: action.alumniStatus.errors,
      };
    case CLEAR_STATE:
    case CANCEL_REGISTRATION:
      // reset everything back to initial state upon cancellation / clear
      return initialState;
    default:
      break;
  }

  return state;
};

export default registrationReducer;
