/* eslint-disable no-unreachable */
import React from 'react';

const TestError = () => {
  throw new Error('An error has occured in Buggy component!');

  return (
    <>
      <h1>Test Error</h1>
    </>
  );
};

export default TestError;
