import * as Yup from 'yup';
import * as PasswordValidationFunctions from './password/ValidationFunctions';

// if it can be generalized more later then great
export const PasswordModels = {
  getValidationItems: () => {
    return [
      {
        // consider getting babel package in here so we can do get displayText = () => instead
        // (i think with using some of the stage2 and lower that becomes possible)
        get displayText() {
          return `Must meet ${this.children.numberRequired} of the following:`;
        },
        get isValid() {
          return this.children.isValid;
        },
        formError: false,
        children: {
          numberRequired: 3,
          isValid: false,
          validationFunction(data) {
            this.requirements.forEach((req) => {
              req.validationFunction(data);
            });
            return this.requirements.filter((req) => req.isValid === true).length >= this.numberRequired;
          },
          requirements: [
            {
              displayText: 'At least <strong>1 capital letter</strong>',
              isValid: false,
              validationFunction(data) {
                this.isValid = PasswordValidationFunctions.meetsCapitalRequirement(data);
              },
            },
            {
              displayText: 'At least <strong>1 lowercase letter</strong>',
              isValid: false,
              validationFunction(data) {
                this.isValid = PasswordValidationFunctions.meetsLowercaseRequirement(data);
              },
            },
            {
              displayText: 'At least <strong>1 numeric character</strong>',
              isValid: false,
              validationFunction(data) {
                this.isValid = PasswordValidationFunctions.meetsNumberRequirement(data);
              },
            },
            {
              displayText: 'At least <strong>1 special character (!, @, #, etc.)</strong>',
              isValid: false,
              validationFunction(data) {
                this.isValid = PasswordValidationFunctions.meetsSpecialCharacterRequirement(data);
              },
            },
          ],
        },
      },
      {
        displayText: 'Must be <strong>at least 8 characters long</strong>',
        isValid: false,
        formError: false,
        validationFunction: PasswordValidationFunctions.meetsLengthRequirement,
      },
      {
        displayText:
          'Must <strong>not contain parts of your full name</strong><br />' +
          '&nbsp;&nbsp;&nbsp;&nbsp;that exceed 4 consecutive characters<br />&nbsp;&nbsp;&nbsp;&nbsp;(ex. jane123, john123)',
        isValid: false,
        formError: false,
        validationFunction: PasswordValidationFunctions.meetsNameRequirement,
      },
    ];
  },
};

// models used for RegistrationForm
export const RegistrationModels = {
  getSchema: () => {
    return Yup.object().shape({
      firstName: Yup.string().min(2, 'Too short.').max(50, 'Too long.').required('The first name is required.'),
      lastName: Yup.string().min(2, 'Too short.').max(50, 'Too long.').required('The last name is required.'),
      dateOfBirth: Yup.date()
        .typeError('Invalid Format')
        // changed dob from date to string to make validation easiser. Trying to use transform on
        // Yup.date was causing weird issues in the form's error validation
        // .matches(
        //   /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/,
        //   'Invalid Format'
        // )
        .required('The date of birth is required.'),
      firstFiveSSN: Yup.string()
        .matches(/(\d{3}-\d{2})/, 'Invalid format.')
        .required('The first 5 digits of your social security are required.'),
      zipCode: Yup.string()
        // This may seem weird. to allow 4 /or more/ digits on the end of the zipcode.
        // BUT -- the mask will take care of preventing that. and this way while the mask is working
        // to remove the 5th digit from the second set, this won't be considered invalid and display
        // the error while the non-usable digit is being pressed
        .matches(/^\d{5}(?:-\d{4,})?$/, 'Invalid format.')
        .required('The zip code is required.'),
      password: Yup.string().max(50, 'Too long.').required('The password is required.'),
      privacyPolicyAndToU: Yup.boolean(),
      // .test(
      //   'privacyPolicy',
      //   'You must accept the statement above to continue.',
      //   value => value === true,
      // ),
      // .required('Required'),
    });
  },
  getInitials: () => {
    return {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      firstFiveSSN: '',
      zipCode: '',
      password: '',
      privacyPolicyAndToU: false,
    };
  },
};

export const BreadcrumbNameMap = {
  '': 'Home',
  registration: 'Alumni Registration',
  renew: 'Renew Registration',
  confirmemail: 'Confirm Email',
  completed: 'Completed Registration',
};
