import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { InputGroup, Col, Form, ButtonToolbar, Button, Alert } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import { conformToMask } from 'react-text-mask';
import { PasswordModels, RegistrationModels } from '../models';
import PasswordValidation from '../password/PasswordValidation';
import { submitRegistration } from '../../store/actions/registrationActions';

import Loader from '../common/Loader';
import loaderStyles from '../common/Loader.module.css';
import './RegistrationForm.css';

// export this as well as the connect() function for unit testing
export const RegistrationFormDisplay = (props) => {
  const { loading, title, apiErrors, selectionsUrl } = props;
  if (apiErrors && apiErrors.length > 0) {
    window.scrollTo(0, 0);
  }

  const [showPassword, setPasswordVisibility] = useState(false);
  const [showSSN, setSSNVisibility] = useState(false);
  const [validationPack, updateValidationPack] = useState({ consecutiveCharactersAllowed: 4 });
  const [validators, updatePasswordValidators] = useState(PasswordModels.getValidationItems());
  const [showPolicyError, togglePolicyError] = useState(false);

  const submitForm = ({ values, setFieldError }) => {
    if (values.privacyPolicyAndToU === false) {
      setFieldError('privacyPolicyAndToU', 'You must accept the statement above to continue.');
      togglePolicyError(true);
      return;
    }

    if (validators.some((pv) => !pv.isValid)) {
      validators.forEach((pv) => {
        const v = pv;
        v.formError = !v.isValid;
      });
      // some way to set this. as of right now this is just putting the is-invalid css on the field but not displaying the error message
      setFieldError('password', 'Must meet all of the top-level requirements below.');
      return;
    }

    props.submitRegistration(values);
  };

  // only fields that we will care about for validation purposes call this function
  const updateValidationPackValue = (e) => {
    const newPack = { ...validationPack };
    const { name, value } = e.currentTarget;
    newPack[name] = value;

    switch (name) {
      case 'firstName':
      case 'lastName':
        newPack.invalidWords = [newPack.firstName || '', newPack.lastName || ''];
        break;
      default:
        break;
    }

    updateValidationPack(newPack);
  };

  const checkPasswordValidators = () => {
    const newValidators = [...validators];

    newValidators.forEach((vi) => {
      const v = vi;
      if (v.children) {
        v.children.isValid = v.children.validationFunction(validationPack);

        if (v.formError && v.children.isValid) {
          v.formError = false;
        }
      } else {
        v.isValid = v.validationFunction(validationPack);

        if (v.formError && v.isValid) {
          v.formError = false;
        }
      }
    });

    updatePasswordValidators(newValidators);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(checkPasswordValidators, [validationPack]);

  const handleMaskChange = (e, sfvFunc) => {
    let mask = [];

    // currently all of these are number-based masks. i'll always want to strip out non-digits to have the user's direct input
    let valueLength = e.currentTarget.value.replace(/\D/g, '').length;

    // in case user is trying to type in the upcoming mask character
    if (e.currentTarget.value.endsWith('/') || e.currentTarget.value.endsWith('-')) {
      valueLength += 1;
    }

    // change mask based on input length (ignoring mask characters) so that mask characters don't appear until they're needed
    switch (e.target.name) {
      case 'dateOfBirth':
        if (valueLength < 3) {
          mask = [/\d/, /\d/];
        } else if (valueLength < 5) {
          mask = [/\d/, /\d/, '/', /\d/, /\d/];
        } else {
          mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        }
        break;
      case 'firstFiveSSN':
        if (valueLength < 4) {
          mask = [/\d/, /\d/, /\d/];
        } else {
          mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/];
        }
        break;
      case 'zipCode':
        mask = [/\d/, /\d/, /\d/, /\d/, /\d/];
        break;
      default:
        break;
    }

    // extra
    sfvFunc(e.target.name, conformToMask(e.currentTarget.value, mask, { guide: false }).conformedValue, false);
  };

  return (
    <>
      {loading ? (
        <div className={loaderStyles.formLoader}>
          <Loader />
        </div>
      ) : null}

      <h1>{title}</h1>
      {apiErrors && apiErrors.length > 0 ? (
        <Alert variant='danger'>
          {apiErrors.map((err, idx) => {
            const key = `error-${idx}`;
            return <div key={key} dangerouslySetInnerHTML={{ __html: err }} />; /* eslint-disable-line react/no-danger */
          })}
        </Alert>
      ) : null}

      {title === 'Alumni Registration' ? (
        <p className='subText small'>
          {'Already Registered? '}
          <a href={selectionsUrl}>Login</a>
        </p>
      ) : (
        <strong>If you forgot your password or have an expired account, you must renew your registration to continue.</strong>
      )}

      <div>
        <Formik
          initialValues={RegistrationModels.getInitials()}
          validationSchema={RegistrationModels.getSchema()}
          onSubmit={(values, { setSubmitting, ...rest }) => {
            submitForm({ values, ...rest });
            setSubmitting(false);
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            // ...formikProps
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} sm={6} controlId='firstName'>
                  <Form.Label>*First Name</Form.Label>
                  <Form.Control
                    type='text'
                    name='firstName'
                    autoComplete='given-name'
                    value={values.firstName}
                    onChange={(e) => {
                      handleChange(e);
                      updateValidationPackValue(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.firstName && !!errors.firstName}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>{errors.firstName}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} controlId='lastName'>
                  <Form.Label>*Last Name</Form.Label>
                  <Form.Control
                    type='text'
                    name='lastName'
                    autoComplete='family-name'
                    value={values.lastName}
                    onChange={(e) => {
                      handleChange(e);
                      updateValidationPackValue(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.lastName && !!errors.lastName}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>{errors.lastName}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} sm={6} controlId='dateOfBirth'>
                  <Form.Label>*Date of Birth</Form.Label>
                  <Form.Control
                    type='text'
                    name='dateOfBirth'
                    autoComplete='bday'
                    value={values.dateOfBirth}
                    onChange={(e) => {
                      handleChange(e);
                      handleMaskChange(e, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.dateOfBirth && !!errors.dateOfBirth}
                    placeholder='MM/DD/YYYY'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>{errors.dateOfBirth}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} controlId='firstFiveSSN'>
                  <Form.Label>*First 5 digits of SSN</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showSSN ? 'text' : 'password'}
                      name='firstFiveSSN'
                      value={values.firstFiveSSN}
                      onChange={(e) => {
                        handleChange(e);
                        handleMaskChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      isInvalid={touched.firstFiveSSN && !!errors.firstFiveSSN}
                      placeholder='123-45'
                      required
                    />
                    <InputGroup.Append>
                      <Button title='Hide/Show SSN' variant='outline-light' onClick={() => setSSNVisibility(!showSSN)}>
                        {showSSN ? <i className='fa fa-eye-slash' /> : <i className='fa fa-eye' />}
                      </Button>
                    </InputGroup.Append>
                    <Form.Control.Feedback type='invalid'>{errors.firstFiveSSN}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} sm={6} controlId='zipCode'>
                  <Form.Label>*Zip Code on File</Form.Label>
                  <Form.Control
                    type='text'
                    name='zipCode'
                    autoComplete='postal-code'
                    value={values.zipCode}
                    onChange={(e) => {
                      handleChange(e);
                      handleMaskChange(e, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.zipCode && !!errors.zipCode}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>{errors.zipCode}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} controlId='password'>
                  <Form.Label>*Create a Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      value={values.password}
                      autoComplete='new-password'
                      onChange={(e) => {
                        handleChange(e);
                        updateValidationPackValue(e);
                      }}
                      onBlur={handleBlur}
                      isInvalid={touched.password && !!errors.password}
                      required
                    />
                    <InputGroup.Append>
                      <Button
                        title='Hide/Show Password'
                        variant='outline-light'
                        onClick={() => setPasswordVisibility(!showPassword)}
                      >
                        {showPassword ? <i className='fa fa-eye-slash' /> : <i className='fa fa-eye' />}
                      </Button>
                    </InputGroup.Append>
                    <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <PasswordValidation title='Passwords must meet the following rules:' validationItems={validators} />
              <br />
              <p className='privacy-notice'>
                {'The personal information you submit through the Alumni Portal and your use of this website is subject to '}
                <a target='_blank' rel='noopener noreferrer' href='https://www.selectmedical.com/privacy/'>
                  {"Select Medical's Privacy Notice"}
                </a>
                {', '}
                <a target='_blank' rel='noopener noreferrer' href='https://www.selectmedical.com/legal/'>
                  Legal
                </a>
                {' and '}
                <a target='_blank' rel='noopener noreferrer' href='https://thehubapps.selectmedical.com/documents/ISP.pdf'>
                  Information Security Policy
                </a>
                .
              </p>
              <Form.Group controlId='privacyPolicyAndToU' className='checkbox'>
                <Form.Check.Input
                  id='privacyPolicyAndToU'
                  name='privacyPolicyAndToU'
                  type='checkbox'
                  value={values.privacyPolicyAndToU}
                  onChange={(e) => {
                    togglePolicyError(values.privacyPolicyAndToU);
                    handleChange(e);
                  }}
                  isInvalid={showPolicyError}
                />
                <Col className='checkbox-label'>
                  <Form.Check.Label>
                    <p>
                      I have read and accept Select Medical&apos;s Privacy Notice, Legal and Information Security Policy
                      statements.
                    </p>
                  </Form.Check.Label>
                </Col>
                <Feedback type='invalid'>You must accept the statement above to continue.</Feedback>
              </Form.Group>
              <ButtonToolbar className='col-10 mx-auto'>
                <Button className='mx-auto' disabled={isSubmitting} type='submit'>
                  {props.loading ? 'Registering...' : 'Register'}
                </Button>
              </ButtonToolbar>
              {
                // For testing/debug. remove before release
                // <pre>{JSON.stringify(validators, null, 2)}</pre>
                // <div>
                //     <pre>{JSON.stringify({ values, touched, errors, showPolicyError }, null, 2)}</pre>
                // </div>
              }
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

RegistrationFormDisplay.propTypes = {
  submitRegistration: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.string),
  selectionsUrl: PropTypes.string.isRequired,
};

RegistrationFormDisplay.defaultProps = {
  apiErrors: [],
};

export const mapStateToProps = (state) => {
  return {
    ...state.registration,
    loading: state.apiCallsInProgress > 0,
    selectionsUrl: state.defaults.selectionsUrl,
  };
};

export default connect(mapStateToProps, { submitRegistration })(RegistrationFormDisplay);
