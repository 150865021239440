import * as Sentry from '@sentry/browser';
import { beginApiCall, apiCallError } from './apiStatusActions';
import * as appService from '../../service/appData';

export const GET_APP_DEFAULTS = 'GET_APP_DEFAULTS';
export const GET_APP_DEFAULTS_SUCCESS = 'GET_APP_DEFAULTS_SUCCESS';

const getAppDefaults = () => {
  return { type: GET_APP_DEFAULTS };
};

const getAppDefaultsSuccess = (defaults) => {
  return { type: GET_APP_DEFAULTS_SUCCESS, appDefaults: defaults };
};

export const handleAppInit = () => (dispatch) => {
  dispatch(getAppDefaults());
  dispatch(beginApiCall());

  return appService
    .getAppDefaultValues()
    .then((result) => {
      // maybe do this here instead of App.js?
      if (result.environmentName !== '') {
        Sentry.init({
          dsn: 'https://32aca96140514b4e85c55453b8dd5199@sentry-dmz.selectmedical.com/28',
          environment: result.environmentName,
        });
      }
      dispatch(getAppDefaultsSuccess(result));
    })
    .catch((error) => {
      dispatch(apiCallError());
      throw error;
    });
};
