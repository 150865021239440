import { toast } from 'react-toastify';

export const handleResponse = async (response) => {
  if (response.ok) {
    return response.json();
  }

  if (response.status === 400) {
    // Something went wrong, throw an error.
    const error = await response.text();
    throw new Error(error);
  }

  throw new Error('Network response was not ok.');
};

export const handleError = (error) => {
  toast.error(error.toString());
  throw error;
};
