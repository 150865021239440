import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './Footer.module.css';

const Footer = () => (
  <div className={styles.footer}>
    <Row noGutters className={`${styles.footerRow} text-center`}>
      <Col xs={7} md={6} className='mx-auto'>
        <span>&copy; 2019 Select Medical Corporation. All Rights Reserved</span>
        <br />
        <br />
        <span>
          For any additional questions, please contact our HR Help line
          <br />
          {'at '}
          <a href='mailto:PayrollTax@selectmedical.com?subject=Alumni%20Portal'>PayrollTax@selectmedical.com</a>
          {' or'}
          <br />
          at 1 (888) 735-6334
        </span>
      </Col>
    </Row>
  </div>
);

export default Footer;
