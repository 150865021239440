export const meetsNameRequirement = ({ password = '', invalidWords = [], consecutiveCharactersAllowed = 2 }) => {
  if (invalidWords.length === 0) {
    return false;
  }

  let result = false;
  invalidWords.forEach((word) => {
    result = word.length > 0 && password.length > 0;
  });

  if (result) {
    const invalidSets = [];
    invalidWords.forEach((word) => {
      const letters = [...word];
      let newSet = '';
      letters.forEach((l) => {
        newSet += l;
        if (newSet.length === consecutiveCharactersAllowed) {
          invalidSets.push(newSet);
          newSet = newSet.slice(1);
        }
      });
    });

    invalidSets.forEach((set) => {
      if (password.toUpperCase().includes(set.toUpperCase())) {
        result = false;
      }
    });
  }

  return result;
};

export const meetsLengthRequirement = ({ password = '', minimumLength = 8 }) => password.length >= minimumLength;
export const meetsCapitalRequirement = ({ password = '' }) => /[A-Z]/.test(password);
export const meetsLowercaseRequirement = ({ password = '' }) => /[a-z]/.test(password);
export const meetsNumberRequirement = ({ password = '' }) => /\d/.test(password);
export const meetsSpecialCharacterRequirement = ({ password = '' }) => /[!@#$%^&*]/.test(password);

// for example:
// meetsGroupedRequirements('testtest', 3, meetsCapitalRequirement, meetsLowercaseRequirement, meetsNumberRequirement, meetsSpecialCharacterRequirement)
export const meetsGroupedRequirements = ({ numberOfGroupsNeeded, ...rest }, ...validationFuncs) => {
  let successful = 0;

  validationFuncs.forEach((vf) => {
    if (typeof vf === 'function' && vf(rest)) {
      successful += 1;
    }
  });

  return successful >= numberOfGroupsNeeded;
};
