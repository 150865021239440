import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import registrationReducer from './reducers/registrationReducer';
import apiStatusReducer from './reducers/apiStatusReducer';
import appReducer from './reducers/appReducer';
import breadcrumbLogger from './middleware/breadcrumbLogger';
import crashReporter from './middleware/crashReporter';

const configureStore = (history, initialState) => {
  const reducers = {
    registration: registrationReducer,
    apiCallsInProgress: apiStatusReducer,
    defaults: appReducer,
  };

  const middleware = [thunk, routerMiddleware(history), breadcrumbLogger, crashReporter];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
};

export default configureStore;
