import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import SMlogo from '../../assets/images/SM-logo-sm.png';

const Header = () => (
  <header role='banner'>
    <div className={styles.logo}>
      <div
        className='col-xs-12 col-sm-10 col-md-9 col-lg-9 col-xl-9 mx-auto d-flex align-items-center'
        style={{ height: '120px' }}
      >
        <Link exact='true' to='/' title='Home'>
          <Image src={SMlogo} title='Home' alt='Select Medical Logo' />
        </Link>
      </div>
    </div>
  </header>
);

export default Header;
