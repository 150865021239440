import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { BreadcrumbNameMap } from '../models';

export const BreadcrumbsDisplay = (props) => {
  const { location } = props;
  let locArr = [];

  if (location === '/') {
    locArr.push('');
  } else {
    locArr = location.split('/');
  }

  const crumbs =
    locArr.length > 1
      ? locArr.map((loc, idx) => {
          const arrCopy = [...locArr];
          const crumbKey = `c-${idx}`;

          // there may be a better way for this..
          const locName = BreadcrumbNameMap[loc] || loc;
          const toLoc = idx === 0 ? '/' : arrCopy.splice(0, idx + 1).join('/');

          let classModifier = '';
          if (idx === 0) {
            classModifier = 'home';
          }
          if (idx === locArr.length - 1) {
            classModifier = 'last';
          }

          return (
            <li key={crumbKey} className={`breadcrumb-item ${classModifier}`}>
              <div className='navigation-title'>
                <Link to={toLoc}>{locName}</Link>
              </div>
            </li>
          );
        })
      : null;

  // rendered
  return (
    <nav role='navigation'>
      <div className='component breadcrumb'>
        <div className='component-content'>
          <ol>{crumbs}</ol>
        </div>
      </div>
    </nav>
  );
};

BreadcrumbsDisplay.propTypes = {
  location: PropTypes.string.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    location: state.router.location.pathname,
  };
};

export default connect(mapStateToProps, {})(BreadcrumbsDisplay);
