import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import ReactPiwik from 'react-piwik';
import { clearRegistrationState } from '../../store/actions/registrationActions';
import styles from './Home.module.css';
import Pdf from '../../assets/Alumni.pdf';

export const HomeDisplay = (props) => {
  const { selectionsUrl, environmentName, clear, w2Note, downTimeNote } = props;

  // don't judge me
  if (ReactPiwik && environmentName !== 'UnitTest') {
    ReactPiwik.push(['setCustomVariable', 1, 'Environment', environmentName, 'visit']);
  }

  clear();

  return (
    <>
      <h1>Alumni Portal</h1>
      <br />
      <p className={styles.highlight}>
        <strong>{downTimeNote}</strong>
      </p>
      <p>Welcome! You&apos;ve landed on the entry point for former Select Medical employees.</p>
      <p>From this portal, you can easily access the following:</p>
      <ul>
        <li>W-2 Forms</li>
        <li>Pay Slips</li>
        <li>Detailed Wage Statements</li>
      </ul>
      <p className={styles.highlight}>
        <strong>{w2Note}</strong>
      </p>
      <p>Click the below video to watch a two minute tutorial on tips and troubleshooting for this portal.</p>
      <div className={`${styles.iframeContainer} justify-content-center`}>
        <iframe
          src='https://players.brightcove.net/5380177696001/default_default/index.html?videoId=6131433688001'
          allowFullScreen
          allow='encrypted-media'
          title='Alumni Portal Tutorial'
        />
      </div>
      <p>
        {'To view your information, please register and receive an email with login instructions. '}
        {'If previously registered, log in using your existing account ID/password.'}
      </p>
      <p>
        {'Note: '}
        <strong>All accounts will expire after 90 days.</strong>
        {' Once your account expires, you will need to '}
        <Link to='/renew'>renew your registration</Link>.
      </p>
      <br />
      <br />
      <ButtonToolbar className='col-10 mx-auto'>
        <Button className='mx-auto' variant='primary' href={selectionsUrl}>
          Log In
        </Button>
        <LinkContainer to='/registration'>
          <Button className='mx-auto' variant='outline-primary'>
            Register
          </Button>
        </LinkContainer>
      </ButtonToolbar>
      <br />
      <p className='small text-center'>
        {'Forgot Password? '}
        <Link to='/renew'>Renew Registration</Link>
      </p>
      <p className='small text-center'>
        {'Receiving an error during log in? '}
        <a href={Pdf} rel='noopener noreferrer' target='_blank'>
          Click Here For A Quick Guide
        </a>
      </p>
    </>
  );
};

HomeDisplay.defaultProps = {
  downTimeNote: '',
};

HomeDisplay.propTypes = {
  selectionsUrl: PropTypes.string.isRequired,
  environmentName: PropTypes.string.isRequired,
  clear: PropTypes.func.isRequired,
  w2Note: PropTypes.string.isRequired,
  downTimeNote: PropTypes.string,
};

export const mapStateToProps = (state) => {
  return {
    selectionsUrl: state.defaults.selectionsUrl,
    environmentName: state.defaults.environmentName,
    w2Note: state.defaults.w2Note,
    downTimeNote: state.defaults.downTimeNote,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => {
      dispatch(clearRegistrationState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeDisplay);
