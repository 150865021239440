import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ButtonToolbar, Button, Alert, InputGroup, FormControl } from 'react-bootstrap';
import ReactPiwik from 'react-piwik';
import { confirmEmailAddress, cancelRegistrationProcess } from '../../store/actions/registrationActions';
import Loader from '../common/Loader';
import loaderStyles from '../common/Loader.module.css';

export const ConfirmEmailDisplay = (props) => {
  const { loading, title, alumni, errors, environment, goHome, confirm, cancel } = props;

  const [currentAlumni, updateAlumni] = useState(alumni);

  const handleChange = useCallback(
    (e) => {
      updateAlumni({ ...currentAlumni, testEmail: e.target.value });
    },
    [currentAlumni]
  );

  const handleConfirm = useCallback(() => {
    confirm(currentAlumni);
  }, [currentAlumni, confirm]);

  const handleCancel = useCallback(() => {
    cancel(currentAlumni);
  }, [currentAlumni, cancel]);

  if (ReactPiwik && alumni.alumniUserName) {
    ReactPiwik.push(['setUserId', alumni.alumniUserName]);
  }

  // this breaks in IE, moved masking to server-side
  // const maskedEmail = alumni.email.replace(/(?<=.{5}).(?=[^@]*?@)/g, '*');

  if (!currentAlumni.maskedEmail) {
    goHome();
  }

  const testingEmail =
    environment !== 'Production' ? (
      <>
        <p style={{ color: 'red' }}>Hello Tester. To send the confirmation email to a different address please enter it below.</p>
        <InputGroup>
          <FormControl name='email' placeholder='test@test.com' autoComplete='email' onChange={handleChange} />
        </InputGroup>
      </>
    ) : null;

  return (
    <>
      {loading ? (
        <div className={loaderStyles.formLoader}>
          <Loader />
        </div>
      ) : null}
      <h1>{title}</h1>
      {errors && errors.length > 0 ? (
        <Alert variant='danger'>
          {errors.map((err, idx) => {
            const key = `ced-${idx}`;
            return <div key={key} dangerouslySetInnerHTML={{ __html: err }} />; /* eslint-disable-line react/no-danger */
          })}
        </Alert>
      ) : null}
      <p>
        {'To verify your account, an email will be sent to '}
        <strong>{alumni.maskedEmail}</strong>
        {' Please confirm this is the correct email address.'}
      </p>
      <p>
        {'If this '}
        <strong>is not</strong>
        {' the correct email address, click on "Cancel Registration" and contact our HR Help line at '}
        <a href='mailto:PayrollTax@selectmedical.com?subject=Alumni%20Portal'>PayrollTax@selectmedical.com</a>
        {' or at 1 (888) 735-6334.'}
      </p>
      <br />
      {testingEmail}
      <br />
      <ButtonToolbar className='col-10 mx-auto'>
        <Button className='mx-auto' onClick={handleConfirm}>
          Confirm Email Address
        </Button>
        <Button variant='outline-primary' className='mx-auto' onClick={handleCancel}>
          Cancel Registration
        </Button>
      </ButtonToolbar>
    </>
  );
};

ConfirmEmailDisplay.propTypes = {
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  goHome: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  alumni: PropTypes.objectOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  environment: PropTypes.string,
};

ConfirmEmailDisplay.defaultProps = {
  errors: [],
  environment: 'Development',
};

export const mapStateToProps = (state) => {
  const { alumni } = state.registration;
  if (process.env.NODE_ENV !== 'production' && !alumni.maskedEmail) {
    alumni.maskedEmail = 'Tes****@Test.com';
  }

  return {
    alumni,
    errors: state.registration.apiErrors,
    loading: state.apiCallsInProgress > 0,
    environment: state.defaults.environmentName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goHome: () => {
      dispatch(push('/'));
    },
    confirm: (values) => {
      dispatch(confirmEmailAddress(values));
    },
    cancel: (values) => {
      dispatch(cancelRegistrationProcess(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailDisplay);
