import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API;
const contentType = 'application/json';

export const submitRegistrationForm = (formValues) => {
  return fetch(`${baseUrl}/Registration/VerifyAlumni`, {
    method: 'post',
    body: JSON.stringify(formValues),
    headers: {
      'Content-Type': contentType,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};

export const confirmEmailAddress = (alumni) => {
  return fetch(`${baseUrl}/Registration/CreateAlumni`, {
    method: 'post',
    body: JSON.stringify(alumni),
    headers: {
      'Content-Type': contentType,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};

export const cancelRegistration = (alumni) => {
  return fetch(`${baseUrl}/Registration/CancelRegistration`, {
    method: 'post',
    body: JSON.stringify(alumni),
    headers: {
      'Content-Type': contentType,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};
