/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './PasswordValidator.module.css';

const PasswordValidator = (props) => {
  const { displayText, isValid, formError, children, isChild } = props;
  let requirements = null;
  if (children.requirements.length > 0) {
    requirements = (
      <ul className='fa-ul'>
        {children.requirements.map((c, idx) => {
          const liKey = `li-${idx}`;
          const pvKey = `pvc-${idx}`;
          return (
            <li key={liKey}>
              <PasswordValidator isChild key={pvKey} {...c} formError={formError} />
            </li>
          );
        })}
      </ul>
    );
  }

  let bulletClass = styles.normal;
  if (isValid) {
    bulletClass = styles.success;
  } else {
    if (formError) {
      bulletClass = styles.error;
    }
  }
  let childListItem = (
    <i style={{ width: '12px', paddingLeft: '4px' }} className={`fa ${styles.stackInner}`}>
      •
    </i>
  );

  if (isValid) {
    childListItem = <i className={`fa fa-check ${styles.success}`} />;
  } else {
    if (formError) {
      childListItem = <i style={{ width: '12px', paddingLeft: '1px' }} className={`fa fa-times ${styles.error}`} />;
    }
  }

  return (
    <span>
      {isChild ? (
        childListItem
      ) : (
        <span className='fa-stack'>
          <i className={`fa fa-circle fa-stack-2x ${styles.normal}`} />
          <i className={`fa fa-circle fa-stack-1x ${styles.stackInner} ${bulletClass}`} />
        </span>
      )}
      &nbsp;
      <span dangerouslySetInnerHTML={{ __html: displayText }} />
      {requirements}
    </span>
  );
};

PasswordValidator.propTypes = {
  displayText: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  formError: PropTypes.bool,
  children: PropTypes.shape({
    numberRequired: PropTypes.number,
    isValid: PropTypes.bool,
    validationFunction: PropTypes.func,
    requirements: PropTypes.arrayOf(PropTypes.object),
  }),
  isChild: PropTypes.bool,
};

PasswordValidator.defaultProps = {
  formError: false,
  isChild: false,
  children: { requirements: [] },
};

export default PasswordValidator;
