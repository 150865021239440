import * as Sentry from '@sentry/browser';

const breadcrumbLogger = (store) => (next) => (action) => {
  const breadcrumb = {
    action,
    actionType: action.type,
    result: next(action),
    nextState: store.getState(),
  };

  Sentry.addBreadcrumb({
    category: 'redux action',
    level: Sentry.Severity.Info,
    data: breadcrumb,
  });
};

export default breadcrumbLogger;
