import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API;

export function getAppDefaultValues() {
  return fetch(`${baseUrl}/AppData/DefaultValues`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export default getAppDefaultValues;
