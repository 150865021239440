import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './ErrorPage.module.css';

const ErrorPage = (props) => {
  const { onReportClick, useRefresh, refresh } = props;

  return (
    <>
      <h1>An error has occured.</h1>
      <br />
      <p className={styles.text}>Sorry about that. Feel free to report this error, or you can head back to the portal.</p>
      <br />
      <ButtonToolbar className='col-10 mx-auto'>
        {useRefresh ? (
          <Button className='mx-auto' onClick={refresh} variant='primary'>
            Return to Alumni Portal
          </Button>
        ) : (
          <LinkContainer exact to='/'>
            <Button className='mx-auto' variant='primary'>
              Return to Alumni Portal
            </Button>
          </LinkContainer>
        )}
        <Button onClick={onReportClick} className='mx-auto' variant='outline-primary'>
          Report
        </Button>
      </ButtonToolbar>
    </>
  );
};

ErrorPage.propTypes = {
  onReportClick: PropTypes.func.isRequired,
  useRefresh: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ErrorPage;
