import * as types from '../actions/apiStatusActions';

const initialState = {
  apiCallsInProgress: 0,
};

const apiStatusReducer = (state = initialState.apiCallsInProgress, action) => {
  if (action.type === types.BEGIN_API_CALL) {
    return state + 1;
  }

  if (action.type.endsWith('_SUCCESS') || action.type === types.API_CALL_ERROR) {
    return state - 1;
  }

  return state;
};

export default apiStatusReducer;
