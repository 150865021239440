import { push } from 'connected-react-router';
import * as registrationService from '../../service/registration';
import { beginApiCall, apiCallError } from './apiStatusActions';

export const SUBMIT_REGISTRATION_FORM = 'SUBMIT_REGISTRATION_FORM';
export const SUBMIT_REGISTRATION_FORM_SUCCESS = 'SUBMIT_REGISTRATION_FORM_SUCCESS';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CANCEL_REGISTRATION = 'CANCEL_REGISTRATION';
export const CANCEL_REGISTRATION_SUCCESS = 'CANCEL_REGISTRATION_SUCCESS';
export const CLEAR_STATE = 'CLEAR_STATE';

const submitRegistrationForm = () => {
  return { type: SUBMIT_REGISTRATION_FORM };
};
const submitRegistrationFormSuccess = (verificationStatus) => {
  return { type: SUBMIT_REGISTRATION_FORM_SUCCESS, verificationStatus };
};

const confirmEmail = () => {
  return { type: CONFIRM_EMAIL };
};
const confirmEmailSuccess = (alumniStatus) => {
  return { type: CONFIRM_EMAIL_SUCCESS, alumniStatus };
};

const clearRegState = () => {
  return { type: CLEAR_STATE };
};

const cancelRegistration = () => {
  return { type: CANCEL_REGISTRATION };
};

export const submitRegistration = (values) => (dispatch) => {
  dispatch(submitRegistrationForm());
  dispatch(beginApiCall());

  return registrationService
    .submitRegistrationForm(values)
    .then((result) => {
      dispatch(submitRegistrationFormSuccess(result));
      if (result.errors.length === 0) {
        dispatch(push('/confirmemail'));
      }
    })
    .catch((error) => {
      dispatch(apiCallError());
      throw error;
    });
};

export const confirmEmailAddress = (values) => (dispatch) => {
  dispatch(confirmEmail());
  dispatch(beginApiCall());

  return registrationService
    .confirmEmailAddress(values)
    .then((result) => {
      dispatch(confirmEmailSuccess(result));
      if (result.errors.length === 0) {
        dispatch(push('/completed'));
      }
    })
    .catch((error) => {
      dispatch(apiCallError());
      throw error;
    });
};

export const cancelRegistrationProcess = (values) => (dispatch) => {
  dispatch(cancelRegistration());

  return registrationService
    .cancelRegistration(values)
    .then(() => {
      dispatch(push('/'));
    })
    .catch((error) => {
      throw error;
    });
};

export const clearRegistrationState = () => (dispatch) => {
  dispatch(clearRegState());
};
