import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Helmet from 'react-helmet';

import Layout from './components/layout/Layout';
import Home from './components/home/Home';
import Registration from './components/alumni/RegistrationForm';
import RegistrationCompleted from './components/alumni/RegistrationCompleted';
import ConfirmEmail from './components/alumni/ConfirmEmail';
import Loader from './components/common/Loader';
import ErrorBoundary from './components/error/ErrorBoundary';

import TestError from './components/error/TestError';
import ErrorPage from './components/error/ErrorPage';

const routes = {
  homeRoute: (routeProps) => (
    <ErrorBoundary key='homeBoundary' useRefresh>
      <Helmet>
        <title>Alumni Portal</title>
      </Helmet>
      <Home {...routeProps} />
    </ErrorBoundary>
  ),
  registrationRoute: (routeProps) => (
    <ErrorBoundary key='regBoundary'>
      <Helmet>
        <title>Alumni Registration</title>
      </Helmet>
      <Registration title='Alumni Registration' {...routeProps} />
    </ErrorBoundary>
  ),
  renewRoute: (routeProps) => (
    <ErrorBoundary key='forgotBoundary'>
      <Helmet>
        <title>Renew Registration</title>
      </Helmet>
      <Registration title='Renew Registration' {...routeProps} />
    </ErrorBoundary>
  ),
  confirmRoute: (routeProps) => (
    <ErrorBoundary key='confEmBoundary'>
      <Helmet>
        <title>Confirm Email</title>
      </Helmet>
      <ConfirmEmail title='Confirm Email' {...routeProps} />
    </ErrorBoundary>
  ),
  completedRoute: (routeProps) => (
    <ErrorBoundary key='completedBoundary'>
      <Helmet>
        <title>Registration Completed</title>
      </Helmet>
      <RegistrationCompleted {...routeProps} />
    </ErrorBoundary>
  ),
  testErrorRoute: (routeProps) => (
    <ErrorBoundary key='testBoundary'>
      <TestError {...routeProps} />
    </ErrorBoundary>
  ),
  errorRoute: (routeProps) => (
    <ErrorBoundary key='testBoundary'>
      <ErrorPage {...routeProps} useRefresh={false} refresh={undefined} onReportClick={undefined} />
    </ErrorBoundary>
  ),
};

export const AppDisplay = (props) => {
  const { loading } = props;

  return (
    <Layout>
      <ToastContainer autoClose={5000} position='top-center' />
      {loading ? (
        <Loader variant='box' />
      ) : (
        <ErrorBoundary key='routeBoundary'>
          <Switch>
            <Route exact path='/' render={routes.homeRoute} />
            <Route path='/registration' render={routes.registrationRoute} />
            <Route path='/renew' render={routes.renewRoute} />
            <Route path='/confirmemail' render={routes.confirmRoute} />
            <Route path='/completed' render={routes.completedRoute} />
            <Route path='/testerror' render={routes.testErrorRoute} />
            <Route path='/errorpage' render={routes.errorRoute} />
          </Switch>
        </ErrorBoundary>
      )}
    </Layout>
  );
};

AppDisplay.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    loading: state.defaults.environmentName === '',
  };
};

export default connect(mapStateToProps, {})(AppDisplay);
