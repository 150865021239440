import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';

export const RegistrationCompletedDisplay = (props) => {
  const { alumni, goHome } = props;

  // this breaks in IE, moved masking to server-side
  // const maskedEmail = email.replace(/(?<=.{5}).(?=[^@]*?@)/g, '*');

  if (!alumni.maskedEmail) {
    goHome();
  }

  return (
    <>
      <div>
        <h1>Registration Completed</h1>
        <p>
          {'Thank you. An email has been sent to '}
          <strong>{alumni.maskedEmail}</strong>
          {' with login instructions.'}
        </p>
        <p>Please note that all accounts expire after 90 days.</p>
      </div>
      <br />
      <LinkContainer exact to='/'>
        <Button className='mx-auto' variant='outline-primary'>
          Return to Alumni Portal
        </Button>
      </LinkContainer>
    </>
  );
};

RegistrationCompletedDisplay.propTypes = {
  goHome: PropTypes.func.isRequired,
  alumni: PropTypes.objectOf(PropTypes.string).isRequired,
};

export const mapStateToProps = (state) => {
  const { alumni } = state.registration;
  if (process.env.NODE_ENV !== 'production' && !alumni.maskedEmail) {
    alumni.maskedEmail = 'Tes****@Test.com';
  }

  return {
    alumni,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goHome: () => {
      dispatch(push('/'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationCompletedDisplay);
